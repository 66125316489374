import { request as axios } from '@/util/request';
import { formatDate } from '@/util/global'
const clean = require('bmh/clean-empty-obj');
const getUrl = require('bmh/url-with-param');

interface QuerySendData {
    code?: string;
    biz_code?: string;
    type?: number;
    status?: number;
    form?: string;
    to?: string;
} 

const API = {
    list: '/boss/storage/out',
    out: '/boss/storage/out/status',
    logistics: '/boss/storage/out/logistics'
};

function formatWare(remote: any = {}) {
    return {
      current: remote.current || 1,
      limit: remote.limit || 10,
      detail: remote.detail || [],
      total: remote.total || 0,
      last: remote.last || 1,
    };
}

export function formatWareData(remote: any = {}) {
    return {
        ...remote,
        create_time: formatDate(remote.create_time),
    }
}

/**
 * 查询进货数据
 * @param current 当前分页
 */
async function queryOut(current: number = 1, send_data?: QuerySendData) {
    clean(send_data);
    const _send_data = {
      ...send_data,
      // limit:2,
      current,
    };
    const url = getUrl(API.list, _send_data);
    const res = await axios.get(url);
    if (res.status !== 200) {
      //message.error((res as any).message);
    }
    const list = formatWare(res.data);
    list.detail = list.detail.map((item: any) => formatWareData(item));
    return list;
}

/**
 * 进货详情
 * @param id 进货 id
 */
async function detailOut(id: number) {
    const url = getUrl(`${API.list}/${id}`);
    const res = await axios.get(url);
    if (res.status !== 200) {
      //message.error((res as any).message);
    }
    return res
}

/**
 * 进货接单
 * @param id 进货 id
 */
 async function logisticsOut(id: number, data: any) {
    return await axios.put(`${API.logistics}/${id}`, data);
}

/**
 * 进货接单
 * @param id 进货 id
 */
 async function out(id: number) {
    return await axios.put(`${API.out}/${id}`);
}
async function getOther(data: any) {
    return await axios.post('/boss/storage/out/other',data)
}


  

export {
    queryOut,
    detailOut,
    logisticsOut,
    out,
    getOther
};