
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { formatDate } from '@/util/global';
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'
// api
import { detailOut, out} from '@/api/storage/out'

// 组件
import CustomModal from '@/component/custom-modal.vue';
@Component({
    components: {
        CustomModal,
    },
})
export default class PurchaseDetail extends Vue {
    is_loading: boolean = false;
    purchase_id: any = '';
    receivingList: any = [];
    next_page: number = 1;
    factoryList: any = [];
    // 表单布局
    form_item_layout: any = {
        labelCol: { span: 2 },
        wrapperCol: { span: 22 },
    };
    model_layout: any = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };

    detailFrom: any = {}

    // 确认修改状态弹窗
    add_modal: any = {
        title: '',
        visible: false,
        is_loading: false,
    }
    // 失败弹窗
    fail_modal: any = {
        title: '抱歉，因以下原因出库失败',
        visible: false,
        content: [],
    };
    printDisabled: boolean = false
    get logo(){
      if(this.detailFrom.status == '待出库' ){
        return require('@/asset/img/await-store.png') 
      }else{
        return require('@/asset/img/accept-store.png')
      }
     
    }
        // 打印账单
    printBill () {
      this.printDisabled = true  // 点击打印按钮禁止重复点击
      const el: HTMLElement = document.getElementById('openPrint') as HTMLElement
      const iframe: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement
      const content = iframe.contentWindow 
      setTimeout(() => {      // 按钮显示为禁止了再去执行截图功能
        html2canvas(el, {
          backgroundColor: null,
          scale: 1.3
        }).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png')
          // eslint-disable-next-line no-unexpected-multiline
          content!.document.write('<html><head><style media="print">@page { margin: 0mm 10mm; }body{margin-top: 50px; text-align: center; width:800px}img{width:1000px}</style></head><body><img src=' + dataURL + '></body></html>')
          setTimeout(() => {
            // eslint-disable-next-line no-unexpected-multiline
            content!.print()
            // eslint-disable-next-line no-unexpected-multiline
            content!.document.body.innerHTML = ''  // 清空上一次打印的内容
          }, 0)
          this.printDisabled = false
        })
      }, 100)
    }
    back() {
        // this.$router.go(-1);
        this.$router.push({
        path: '/storage/out',
        query: { page: this.$route.query?.page || '1' },
        });
    }
    fromDetail(remote: any = {}) {
        return {
            ...remote,
            status: remote.status == 1?'待出库':'已出库',
            update_time: formatDate(remote.update_time),
            // order_pd: [{pd_name: 1},{pd_name: 2},{pd_name: 1},{pd_name: 2},{pd_name: 1},{pd_name: 2},{pd_name: 1},{pd_name: 2},]
        };
    }

    // 接单
    add() {
      this.add_modal.title = '出库';
      this.add_modal.data = this.detailFrom;
      this.add_modal.visible = true;
      this.add_modal.content = ['确定全部出库了吗？']
    }
    // 提交
    @changeLoading(['is_loading'])
    async submit() {
        this.add_modal.is_loading = true;
        const res = await out(this.add_modal.data.id);
        this.add_modal.is_loading = false;
        this.add_modal.visible = false;
        if (res.status !== 200) {
        this.fail_modal.content = [...(res as any).message.split('\n')];
        this.fail_modal.visible = true;
        return;
        }
        this.$message.success('出库成功');
        this.$router.push({
            path: '/storage/out',
            query: { page: this.$route.query?.page || '1' },
        });
    }
    // 取消
    cancel() {
        this.clearModal();
    }
    // 清空模态框数据
    clearModal() {
        // 清除校验信息
        this.add_modal = {
            title: '',
            visible: false,
            is_loading: false,
        }
    }
    
    //分页
    // paginationOption(data: any) {
    //   if (data) {
    //     return {
    //       current: 1,
    //       total: data.length,
    //       pageSize: 10,
    //       showTotal: () => `共${data.length}条，每页10条`,
    //     };
    //   }
    // }
    // onTableChange(pagination: any) {
    //   this.next_page = pagination.current;
    // }
    creatQrCode() {
      new QRCode(this.$refs.QrCodeUrl, {
        text: this.detailFrom.type ===4 ? this.detailFrom.code : this.detailFrom.biz_code, // 需要转换为二维码的内容
        width: 70,
        height: 70,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
    @changeLoading(['is_loading'])
    async created() {
      if (this.$route.query.id) {
        this.purchase_id = this.$route.query.id;
        const res = await detailOut(this.purchase_id);
        this.detailFrom = this.fromDetail(res.data);
        this.creatQrCode()
      }
    }
}
